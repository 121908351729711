import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NoTitleLayout from "../components/no_title_layout";
import Socials from "../components/socials";
import Hello from "./index_includes/_hello";
import AcademicTimeline from "./index_includes/_academic_timeline";
export const timelineItems = [{
  start: "2019/09",
  title: "DPhil, Statistics",
  subtitle: "Oriel College, University of Oxford",
  description: "Ongoing"
}, {
  start: "2018/10",
  end: "2019/07",
  title: "MMath",
  subtitle: "Magdalene College, University of Cambridge",
  description: "Honors Pass with Distinction"
}, {
  start: "2015/10",
  end: "2018/07",
  title: "BA, Mathematics",
  subtitle: "Magdalene College, University of Cambridge",
  description: "Class I Honors"
}];
export const _frontmatter = {
  "title": "Home"
};
const layoutProps = {
  timelineItems,
  _frontmatter
};
const MDXLayout = NoTitleLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Hello mdxType="Hello" />
    <p>{`Welcome to my site! I'm a `}<em parentName="p">{`PhD statistics student`}</em>{`. This website is intended as a central hub for my teaching, research and outreach activities. I do hope you enjoy your stay!`}</p>
    <h1>{`About me`}</h1>
    <p>{`I'm a member of all of the following:`}</p>
    <ul>
      <li parentName="ul">{`The University of Oxford`}</li>
      <li parentName="ul">{`The Department of Statistics`}</li>
      <li parentName="ul">{`The Mathematics of Random Systems CDT`}</li>
      <li parentName="ul">{`Oriel College`}</li>
    </ul>
    <p>{`See my `}<a parentName="p" {...{
        "href": "/research"
      }}>{`research page`}</a>{` for more on my research interests. Outside of academia I enjoy fiddling around with little code projects, like an automatic flash card creator or this website. Sometimes I even complete them!`}</p>
    <h1>{`Academic timeline`}</h1>

    <AcademicTimeline timelineItems={timelineItems} mdxType="AcademicTimeline" />
    <p>{`A full CV is available upon request.`}</p>
    <h1>{`Contact me`}</h1>
    <p>{`Please find below (and always in the footer) links to assorted places I can be found:`}</p>
    <div style={{
      width: "100%",
      fontSize: "3em",
      display: "flex",
      justifyContent: "center"
    }}>
  <Socials mdxType="Socials" />
    </div>
    <p>{`An email to `}<a parentName="p" {...{
        "href": "mailto:zheneng.xie@maths.ox.ac.uk"
      }}>{`zheneng.xie@maths.ox.ac.uk`}</a>{` would be the most preferred form of contact. My pronouns are he/him.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      